import React from 'react';
import { Link } from 'react-router-dom';

export const vystKontr = () => (
    <div>
        <section className="container-fluid">
            <div className="row">
                <div className="col-md-4 techBox">
                    <h2 className="oSpolTitle">Naša technológia</h2>
                    <ul className="sideBox">
                        <Link to="/delenie-materialu"><li className="sideNavbar">Delenie materiálu</li></Link>
                        <Link to="/sustruznicke-centra"><li className="sideNavbar">Sústružnické centrá</li></Link>
                        <Link to="/frezovacie-centra"><li className="sideNavbar">Frézovacie centrá</li></Link>
                        <Link to="/brusenie"><li className="sideNavbar">Brúsenie</li></Link>
                        <Link to="/omielacie-zariadenia"><li className="sideNavbar">Omieľacie zariadenia</li></Link>
                        <Link to="/vystupna-kontrola"><li className="sideNavbar">Výstupná kontrola</li></Link>
                    </ul>
                </div>
                <div className="col-md-8 animated fadeIn">
                    <h1 className="mainTitleTech mobileTitle fScreenVystK">Výstupná kontrola</h1>

                    <h5 className="titleMachine">3D meradlo WENZEL Xorbit XO 87</h5>

                    <h5 className="titleMachine">Optické meradlo KEYENCE IM SERIES</h5>
                    <h5 className="titleMachine">Kontúromer MITUTOYO CONTRACER CV-3200</h5>
                </div>
            </div>
        </section>
    </div>
)
