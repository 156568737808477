import React from 'react'
import ReactWOW from 'react-wow';

import certSJ from '../multimedia/certifikaty/certifikat_SJ.png';
import certAJ from '../multimedia/certifikaty/certifikat_AJ.png';

export const isoCert = () => (
        <div className="container">
            <section>
                <ReactWOW animation='fadeIn'>
                    <h1 className="certTitle">ISO certifikáty</h1>
                    <hr className="hrCustom"></hr>
                    <div className="row certPad">
                        <div className="col-lg-6">
                            <img src={certSJ} className="certif" alt="certSJ"/>
                        </div>
                        <div className="col-lg-6" >
                            <img src={certAJ} className="certif" alt="certAJ"/>
                        </div>
                    </div>
                </ReactWOW>
            </section>
        </div>
)
