import React, {useEffect, useState} from "react";

import banner from "../multimedia/tlmic/banner-tlmic-ekometal.jpg";

export const Modal = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const modalOpened = sessionStorage.getItem("modalOpened");
        if (modalOpened) {
            const now = new Date().getTime();
            const lastOpened = new Date(JSON.parse(modalOpened)).getTime();
            const diff = now - lastOpened;

            // Check if 1 hour have passed (3600000 milliseconds in a day)
            if (diff >= 3600000) {
                sessionStorage.removeItem("modalOpened");
                setShowModal(true);
            }
        } else {
            setShowModal(true);
        }
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
        sessionStorage.setItem("modalOpened", JSON.stringify(new Date()));
    };

    const redirectToDetailSilencer = () => {
        window.location.href = '/tlmic';
        setShowModal(false);
        sessionStorage.setItem("modalOpened", JSON.stringify(new Date()));
    };

    return (
        <div>
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <img src={banner} alt="Ekometal tlmič hluku výstrelu SILEN-TEC"/>
                        <div className='modal-buttons'>
                            <button onClick={handleCloseModal}>Zavrieť</button>
                            <button onClick={redirectToDetailSilencer}>Prejsť na detail</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}