import React from 'react'
import ReactWOW from "react-wow";

import banner from "../multimedia/tlmic/banner-tlmic-ekometal.jpg";
import letak from "../multimedia/tlmic/letak-tlmic-ekometal.jpg";
import tlmic1 from "../multimedia/tlmic/tlmic-ekometal-1.jpg";
import tlmic2 from "../multimedia/tlmic/tlmic-ekometal-2.jpg";
import tlmic3 from "../multimedia/tlmic/tlmic-ekometal-pes-1.jpg";
import tlmic4 from "../multimedia/tlmic/tlmic-ekometal-pes-2.jpg";
import tlmic5 from "../multimedia/tlmic/tlmic-ekometal-zrbn-1.jpg";
import tlmic6 from "../multimedia/tlmic/tlmic-ekometal-zrbn-2.jpg";
import tlmic7 from "../multimedia/tlmic/tlmic-ekometal-zrbn-3.jpg";

export const DetailTlmic = () => {
    return (
        <section className="container detail-tlmic">
            <div className="row">
                <ReactWOW animation='fadeIn' delay="100ms">
                    <div className="col-sm-12">
                        <h1 className="techTitle">Tlmič hluku výstrelu SILEN-TEC</h1>
                        <hr className="hrCustomTlmic"></hr>
                    </div>
                </ReactWOW>
            </div>
            <ReactWOW animation='fadeIn' delay="100ms">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>Technický popis</h3>
                        <p>Tlmič SILEN-TEC je presadený expanzno-adsorbčný viackomorový tlmič hluku výstrelu.
                            Obal tlmiča je vyrobený z povrchovo eloxovaného duralu AW7075.
                            Stredový diel (jadro) je vyrobený z nerezovej ocele triedy 1.4301.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <h3>Princíp činnosti</h3>
                        <p>Princíp činnosti spočíva v kontrolovanej expanzii povýstrelových splodín postupne v komorách
                            1 a
                            2.
                            Následne sú splodiny ochladzované, expandované v komorách č. 3 až 12 a následne vytlačené
                            ústím
                            tlmiča.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <h3>Technické údaje</h3>

                        <ul className="tlmic-ul">
                            <li>Vonkajší priemer: 50 mm</li>
                            <li>Celková dĺžka: 240 mm</li>
                            <li>Vnútorný priemer: 46,5 mm</li>
                            <li>Presadenie: 80 mm</li>
                            <li>Rážové skupiny: 6,5 mm / 0,30" / 8 mm / 9,3 mm</li>
                            <li>Závit pre osadenie na hlaveň: M18x1 mm, ostatné závity sa montujú pomocou adaptérov (v
                                cene)
                            </li>
                            <li>Hmotnosť: 495 g</li>
                            <li>Úcinnosť: 32-38 dB</li>
                        </ul>
                    </div>
                </div>
            </ReactWOW>

            <ReactWOW animation='fadeIn' delay="100ms">
                <div className="row stiahnutie">
                    <div className="col-sm-12">
                        <a href={banner} className={'link'} download={banner}>
                            Stiahnuť dokument <i className={'fa fa-download'}></i>
                        </a>
                        <a href={letak} className={'link'} download={banner}>
                            Stiahnuť leták <i className={'fa fa-download'}></i>
                        </a>
                    </div>
                </div>
            </ReactWOW>
            <div className="row">
                <ReactWOW animation='fadeInLeft' delay="200ms">
                    <div className="col-sm-4">
                        <img src={tlmic1} className="imgGal" alt="Ekometal tlmič hluku výstrelu SILEN-TEC"/>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="400ms">
                    <div className="col-sm-4">
                        <img src={tlmic2} className="imgGal" alt="Ekometal tlmič hluku výstrelu SILEN-TEC"/>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="600ms">
                    <div className="col-sm-4">
                        <img src={tlmic6} className="imgGal" alt="Ekometal tlmič hluku výstrelu SILEN-TEC"/>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="200ms">
                    <div className="col-sm-4">
                        <img src={tlmic4} className="imgGal" alt="Ekometal tlmič hluku výstrelu SILEN-TEC"/>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="600ms">
                    <div className="col-sm-4">
                        <img src={tlmic3} className="imgGal" alt="Ekometal tlmič hluku výstrelu SILEN-TEC"/>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="600ms">
                    <div className="col-sm-4">

                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="400ms">
                    <div className="col-sm-4">
                        <img src={tlmic5} className="imgGal" alt="Ekometal tlmič hluku výstrelu SILEN-TEC"/>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="600ms">
                    <div className="col-sm-4">
                        <img src={tlmic7} className="imgGal" alt="Ekometal tlmič hluku výstrelu SILEN-TEC"/>
                    </div>
                </ReactWOW>
            </div>
        </section>
    )
}