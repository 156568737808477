import React from 'react';
import ReactWOW from 'react-wow';

import g1 from '../multimedia/fotkyGaleria/mikron.png';
import g2 from '../multimedia/fotkyGaleria/meradlo.png';
import g3 from '../multimedia/fotkyGaleria/pinnacleJergi.png';
import g4 from '../multimedia/fotkyGaleria/prstence.png';
import g5 from '../multimedia/fotkyGaleria/sustruh.png';
import g6 from '../multimedia/fotkyGaleria/auto_firma.jpg';
import g7 from '../multimedia/fotkyGaleria/cela_firma.jpg';
import g8 from '../multimedia/fotkyGaleria/Fero_merac.jpg';
import g9 from '../multimedia/fotkyGaleria/hala.jpg';
import g10 from '../multimedia/fotkyGaleria/hala_dodavka.jpg';
import g11 from '../multimedia/fotkyGaleria/hala_outside.jpg';
import g12 from '../multimedia/fotkyGaleria/hala_stroje.jpg';
import g13 from '../multimedia/fotkyGaleria/hala2.jpg';
import g14 from '../multimedia/fotkyGaleria/hala3.jpg';
import g15 from '../multimedia/fotkyGaleria/krize.jpg';
import g16 from '../multimedia/fotkyGaleria/male_priruby.jpg';
import g17 from '../multimedia/fotkyGaleria/marek.jpg';
import g18 from '../multimedia/fotkyGaleria/PandP.jpg';
import g19 from '../multimedia/fotkyGaleria/pinnacle.jpg';
import g20 from '../multimedia/fotkyGaleria/priruby.jpg';
import g21 from '../multimedia/fotkyGaleria/dodavka.jpg';


export const galeria = () => (
    <div className="container animated fadeIn">
        <div className="row">
        <ReactWOW animation='fadeIn'>
            <div className="col-sm-4"></div>
            <div className="col-sm-4 text-center galTitle">
                <h1 className="titleGal">Galéria</h1>
                <hr className="hrCustomGal"></hr>
            </div>
            <div className="col-sm-4"></div>
        </ReactWOW>
        </div>
        <div className="row">
        <ReactWOW animation='fadeInLeft' delay="200ms">
            <div className="col-sm-4">
                <img src={g21} className="imgGal" alt="mikron"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="400ms">
            <div className="col-sm-4">
                <img src={g2} className="imgGal" alt="meradlo"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="600ms">
            <div className="col-sm-4">
                <img src={g3} className="imgGal" alt="pinnacle"/>
            </div>
        </ReactWOW>
        </div>
        <div className="row">
        <ReactWOW animation='fadeInLeft' delay="800ms">
            <div className="col-sm-4">
                <img src={g4} className="imgGal" alt="prstence"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="1000ms">
            <div className="col-sm-4">
                <img src={g6} className="imgGal" alt="sustruh"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="1200ms">
            <div className="col-sm-4">
                <img src={g5} className="imgGal" alt="Galeria"/>
            </div>
        </ReactWOW>
        </div>
        <div className="row">
        <ReactWOW animation='fadeInLeft' delay="1400ms">
            <div className="col-sm-4">
                <img src={g7} className="imgGal" alt="prstence"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="1600ms">
            <div className="col-sm-4">
                <img src={g8} className="imgGal" alt="sustruh"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="1800ms">
            <div className="col-sm-4">
                <img src={g9} className="imgGal" alt="Galeria"/>
            </div>
        </ReactWOW>
        </div>    
        <div className="row">
        <ReactWOW animation='fadeInLeft' delay="2000ms">
            <div className="col-sm-4">
                <img src={g10} className="imgGal" alt="prstence"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="2200ms">
            <div className="col-sm-4">
                <img src={g14} className="imgGal" alt="sustruh"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="2400ms">
            <div className="col-sm-4">
                <img src={g12} className="imgGal" alt="Galeria"/>
            </div>
        </ReactWOW>
        </div>    
        <div className="row">
        <ReactWOW animation='fadeInLeft' delay="2600ms">
            <div className="col-sm-4">
                <img src={g13} className="imgGal" alt="prstence"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="2800ms">
            <div className="col-sm-4">
                <img src={g11} className="imgGal" alt="sustruh"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="3000ms">
            <div className="col-sm-4">
                <img src={g15} className="imgGal" alt="Galeria"/>
            </div>
        </ReactWOW>
        </div> 
        <div className="row">
        <ReactWOW animation='fadeInLeft' delay="3200ms">
            <div className="col-sm-4">
                <img src={g16} className="imgGal" alt="prstence"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="3400ms">
            <div className="col-sm-4">
                <img src={g17} className="imgGal" alt="sustruh"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="3600ms">
            <div className="col-sm-4">
                <img src={g18} className="imgGal" alt="Galeria"/>
            </div>
        </ReactWOW>
        </div>  
        <div className="row">
        <ReactWOW animation='fadeInLeft' delay="3800ms">
            <div className="col-sm-4">
                <img src={g19} className="imgGal" alt="prstence"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="4000ms">
            <div className="col-sm-4">
                <img src={g20} className="imgGal" alt="sustruh"/>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeInLeft' delay="4200ms">
            <div className="col-sm-4">
                <img src={g1} className="imgGal" alt="Galeria"/>
            </div>
        </ReactWOW>
        </div>             
    </div>
);