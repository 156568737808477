import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`

    #homeFooter {
        background-color: #00215E;
        padding: 20px 0 0 0;
        border-top: 2px solid #dc242a;
    }

    #col1 {
        text-align: center;
    }

    #col2 {
        margin: 0 auto;
        text-align: center;
    }

    #col3 {
        text-align: center;
        font-size: 25px;
        color: white;

        margin: 0 auto;
    }

    #col1, #col2, #col3 {
        padding: 10px 10px;
    }

    .odkaz {
        color: white;
        font-size: 20px;
    }

    .odkaz:hover {
        color: #dc242a;
        text-decoration: none;
    }

    .odkazInfo {
        color: #dc242a;
        text-decoration: none;
    }

    .odkazInfo:hover {
        color: white;
        text-decoration: none;
    }

    .fa-phone {
        font-size: 22px;
    }

    .fa-envelope {
        font-size: 20px;
        padding-right: 5px;
    }

    @media screen and (max-width: 768px) {
        .fa-envelope {
            font-size: 18px;
        }

        a.odkaz {
            font-size: 19px;
        }
    }

    .fa-arrow-right {
        font-size: 20px;
        padding-left: 5px;
        font-weight: 500;
    }

    #copyrightFooter, .footer-copyright {
        border-top: 2px solid #dc242a;
        background-color: #00215E;
        color: white;
    }

    .footer-copyrightSign {
        border-top: 2px solid #dc242a;
        background-color: #00215E;
        color: white;
    }

    .linkFooter {
        color: #dc242a;
    }

    .linkFooter:hover {
        color: white;
        text-decoration: none;
    }
`;

export const EkoFooter = () => {
    let aktualnyRok = new Date().getFullYear();

    return (
        <Styles>
            <footer className="container-fluid" id="ekoContainer">
                <div className="page-footer">
                    <div className="row" id="homeFooter">
                        <div className="col-md-4" id="col1">
                            <h3 className="hodinyTitle">Administratívne hodiny</h3>
                            <table className="hodinyTable">
                                <tbody>
                                <tr>
                                    <td>Pondelok</td>
                                    <td>6:00 - 14:00</td>
                                </tr>
                                <tr>
                                    <td>Utorok</td>
                                    <td>6:00 - 14:00</td>
                                </tr>
                                <tr>
                                    <td>Streda</td>
                                    <td>6:00 - 14:00</td>
                                </tr>
                                <tr>
                                    <td>Štvrtok</td>
                                    <td>6:00 - 14:00</td>
                                </tr>
                                <tr>
                                    <td>Piatok</td>
                                    <td>6:00 - 14:00</td>
                                </tr>
                                <tr>
                                    <td>Sobota</td>
                                    <td>Zatvorené</td>
                                </tr>
                                <tr>
                                    <td>Nedeľa</td>
                                    <td>Zatvorené</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-4" id="col2">
                            <h3 className="videoTitle">Ekometal, spol. s r.o.</h3>
                            <div className="embed-responsive embed-responsive-16by9 mb-4 footerVideo">
                                <iframe className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/BKGfpsOaz44?controls=0&rel=0&disablekb=1&showinfo=0&modestbranding=0&html5=1&iv_load_policy=3&autoplay=0&end=0&loop=0&playsinline=0&start=0&nocookie=false&enablejsapi=1&origin=https%3A%2F%2Fekometal.sk&widgetid=1"
                                        title="Ekometalvideo"/>
                            </div>
                        </div>
                        <div className="col-md-4" id="col3">
                            <h3 className="dotazTitle">Máte otázky?</h3>
                            <div className="contactBox">
                                <p>Kontaktujte nás:</p>
                                <a href="tel:+421907832860" className="odkaz"><i className="fa fa-phone"></i> +421 907
                                    832
                                    860</a>
                                <br></br>
                                <a href="mailto:ekometal@ekometal.sk" className="odkaz"><i
                                    className="fa fa-envelope"></i>ekometal@ekometal.sk</a>
                                <br></br>
                                <a href="/kontakt" className="odkazInfo">Viac info<i className="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 p-0" style={{backgroundColor: "#00215E"}}>
                            <div className=" text-left pt-3" id="copyrightFooter"><p
                                className="signCompanyFooter">© {aktualnyRok} &nbsp;Všetky práva vyhradené Ekometal, spol. s r.o.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 footer-copyrightSign">
                            <p className="text-right pt-3 signMe">Web vytvoril <a href="mailto:r.bruchanek@gmail.com"
                                                                                  className="linkFooter">Radovan
                                Bruchánek</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        </Styles>
    )
}
