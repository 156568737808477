import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

//components
import {NavigationBar} from './components/NavigationBar';
import {EkoFooter} from './components/footer';

//components
import {HomePage} from './pages/homePage';
import {oSpol} from './pages/oSpol';
import {technologia} from './pages/technologia';
import {dotacie} from './pages/dotacie';
import {isoCert} from './pages/isoCert';
import {EkoKontakt} from './pages/kontakt';
import {galeria} from './pages/galeria';
import {NotFound} from './pages/404';
import {maintanancePage} from './pages/maintanancePage';

//SideNavbar//
import {delMat} from './pages/sideNavbar/delMat';
import {sustrCen} from './pages/sideNavbar/sustrCen';
import {frezCen} from './pages/sideNavbar/frezCen';
import {brusenie} from './pages/sideNavbar/brusenie';
import {omielZar} from './pages/sideNavbar/omielZar';
import {vystKontr} from './pages/sideNavbar/vystKont';
import {Modal} from "./components/modal";
import {DetailTlmic} from "./pages/detail-tlmic";

class App extends Component {
    render() {
        return (
            <div>
                <Modal/>
                <React.Fragment>
                    <NavigationBar/>
                    <Router>
                        <Switch>
                            <Route exact path="/" component={HomePage}/>
                            <Route path="/kontakt" component={EkoKontakt}/>
                            <Route path="/technologia" component={technologia}/>
                            <Route path="/delenie-materialu" component={delMat}/>
                            <Route path="/sustruznicke-centra" component={sustrCen}/>
                            <Route path="/frezovacie-centra" component={frezCen}/>
                            <Route path="/brusenie" component={brusenie}/>
                            <Route path="/omielacie-zariadenia" component={omielZar}/>
                            <Route path="/vystupna-kontrola" component={vystKontr}/>
                            <Route path="/o-spolocnosti" component={oSpol}/>
                            <Route path="/projekty" component={dotacie}/>
                            <Route path="/ISO-certifikaty" component={isoCert}/>
                            <Route path="/galeria" component={galeria}/>
                            <Route path="/tlmic" component={DetailTlmic}/>
                            <Route path="/maintanancePage" component={maintanancePage}/>
                            <Route path="*" component={NotFound}/>
                        </Switch>
                    </Router>
                    <EkoFooter/>
                </React.Fragment>
            </div>
        );
    }
}

export default App;
