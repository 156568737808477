import React from 'react';
import { Link } from 'react-router-dom';

export const frezCen = () => (
    <div>
        <section className="container-fluid">
            <div className="row">
                <div className="col-md-4 techBox">
                    <h2 className="oSpolTitle">Naša technológia</h2>
                    <ul className="sideBox">
                        <Link to="/delenie-materialu"><li className="sideNavbar">Delenie materiálu</li></Link>
                        <Link to="/sustruznicke-centra"><li className="sideNavbar">Sústružnické centrá</li></Link>
                        <Link to="/frezovacie-centra"><li className="sideNavbar">Frézovacie centrá</li></Link>
                        <Link to="/brusenie"><li className="sideNavbar">Brúsenie</li></Link>
                        <Link to="/omielacie-zariadenia"><li className="sideNavbar">Omieľacie zariadenia</li></Link>
                        <Link to="/vystupna-kontrola"><li className="sideNavbar">Výstupná kontrola</li></Link>
                    </ul>
                </div>
                <div className="col-md-8 animated fadeIn">
                    <h1 className="mainTitleTech">Frézovacie centrá 3 – osé</h1>

                    <h5 className="titleMachine">Pinnacle VMC 1160 – 2 ks</h5>
                    <p className="machineNote">Upínacia plocha: 1300 x 610</p>
                    <p className="machineNote">Pojazdy X-Y-Z : 1270 x 610 x 610</p>
                    <p className="machineNote">Max. zaťaženie stola: 800 kg</p>

                    <h5 className="titleMachine">Tongtai TMV - 1050 AII</h5>
                    <p className="machineNote">Upínacia plocha: 1100 x 600</p>
                    <p className="machineNote">Pojazdy X-Y-Z : 1050 x 600 x 530</p>
                    <p className="machineNote">Max. zaťaženie stola: 800 kg</p>
                    
                    <h5 className="titleMachine">Pinnacle VMC 1000</h5>
                    <p className="machineNote">Upínacia plocha: 1200 x 510</p>
                    <p className="machineNote">Pojazdy X-Y-Z : 1020 x 560 x 560</p>
                    <p className="machineNote">Max. zaťaženie stola: 600 kg</p>

                    <h5 className="titleMachine">Pinnacle VMC 650</h5>
                    <p className="machineNote">Upínacia plocha: 800 x 400</p>
                    <p className="machineNote">Pojazdy X-Y-Z : 680 x 560 x 560</p>
                    <p className="machineNote">Max. zaťaženie stola: 500 kg</p>

                    <h5 className="titleMachine">MAS 1000 QUICK</h5>
                    <p className="machineNote">Upínacia plocha: 1300 x 600</p>
                    <p className="machineNote">Pojazdy X-Y-Z : 1016 x 610 x 660</p>
                    <p className="machineNote">Max. zaťaženie stola: 800 kg</p>

                    <h5 className="titleMachine">XYZ 2-OP</h5>
                    <p className="machineNote">Upínacia plocha: 450 x 380</p>
                    <p className="machineNote">Pojazdy X-Y-Z : 356 x 450 x 432</p>
                    <p className="machineNote">Max. zaťaženie stola: 200 kg</p>

                    <h1 className="mainTitleTech">Frézovacie centrum 5 – osé</h1>

                    <h5 className="titleMachine">TONGTAI 5-AXIS CT-350</h5>
                    <p className="machineNote">Upínacia plocha: 380 x 220</p>
                    <p className="machineNote">Pojazdy X-Y-Z : 400 x 510 x 510</p>
                    <p className="machineNote">Max. zaťaženie stola: 200 kg</p>

                </div>
            </div>
        </section>
    </div>
)
