import React from 'react';
import ReactWOW from 'react-wow';

import '../App.css';

import kontakt1 from '../multimedia/kontakt/PK.jpg';
import kontakt2 from '../multimedia/kontakt/PB.jpg';
import kontakt3 from '../multimedia/kontakt/janStejskalKon.jpg';
import kontakt4 from '../multimedia/kontakt/sillouette.jpg';
import kontakt5 from '../multimedia/kontakt/vieraKriskovaKon.jpg';

export const EkoKontakt = () => (
<div>
    <section className="container">
        <ReactWOW animation='fadeIn'>
            <div className="kontaktTitle">
                <h1 className="mt-title">Kontaktné osoby</h1>
                <hr className="hrCustom"></hr>
            </div>
        </ReactWOW>
        <div className="row">
        <ReactWOW animation='fadeIn'>
            <div className="col-sm-4">
                <div className="conContainer">
                    <img src={kontakt1} className="imgCon img-thumbnail" alt="PK"/>
                        <h5 className="card-title namePK">Ing. Peter Kardoš</h5>
                        <p className="card-text funPK">Konateľ</p>
                    <div className="conInfo_phone_email">
                        <a href="tel:+421907832860" className="telCon"><i className="fa fa-phone phoneCon"></i> +421 907 832 860</a><br></br>
                        <a href="mailto:konatel@ekometal.sk" className="mailCon"><i className="fa fa-envelope enveCon"></i> konatel@ekometal.sk</a>
                    </div>
                </div>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeIn'>
            <div className="col-sm-4">
                <div className="conContainer">
                    <img src={kontakt2} className="imgCon img-thumbnail" alt="PB"/>
                    <h5 className="card-title namePB">Peter Brndiar</h5>
                    <p className="card-textK2 funPB">Technologické oddelenie</p>
                    <div className="conInfo_phone_email">
                        <a href="tel:+421907525744" className="telCon"><i className="fa fa-phone phoneCon"></i> +421 907 525 744</a><br></br>
                        <a href="mailto:technolog@ekometal.sk" className="mailCon"><i className="fa fa-envelope enveCon"></i> technolog@ekometal.sk</a>
                    </div>
                </div>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeIn'>
            <div className="col-sm-4">
                <div className="conContainer">
                    <img src={kontakt3} className="imgCon img-thumbnail" alt="JS"/>
                    <div>
                        <h5 className="card-title nameJS">Ján Stejskal</h5>
                        <p className="card-textK3 funJS">Vedúci výroby</p>
                    </div>
                    <div className="conInfo_phone_email">
                        <a href="tel:+421905937541" className="telCon"><i className="fa fa-phone phoneCon"></i> +421 905 937 541</a><br></br>
                        <a href="mailto:vyroba@ekometal.sk" className="mailCon"><i className="fa fa-envelope enveCon"></i> vyroba@ekometal.sk</a>
                    </div>
                </div>
            </div>
        </ReactWOW>
        </div>
        <div className="row">
        <ReactWOW animation='fadeIn'>
            <div className="col-sm-4">
                <div className="conContainer">
                    <img src={kontakt4} className="imgCon img-thumbnail" alt="MB"/>                
                    <h5 className="card-titleK4 nameMB">Ing. František Demko</h5>
                    <p className="card-textK4 funMB">Manažér kvality</p>
                    <div className="conInfo_phone_email">
                        <a href="tel:+421907450672" className="telCon"><i className="fa fa-phone phoneCon"></i> +421 907 450 672</a><br></br>
                        <a href="mailto:kvalita@ekometal.sk" className="mailCon"><i className="fa fa-envelope enveCon"></i> kvalita@ekometal.sk</a>
                    </div>
                </div>
            </div>
        </ReactWOW>
        <ReactWOW animation='fadeIn'>
            <div className="col-sm-4">
                <div className="conContainer">
                    <img src={kontakt5} className="imgCon img-thumbnail" alt="VK"/>
                    <h5 className="card-titleK5 nameVK">Ing. Viera Krišková</h5>
                    <p className="card-textK5 funVK">Ekonomické oddelenie</p>
                    <div className="conInfo_phone_email">
                        <a href="tel:+421948084404" className="telCon"><i className="fa fa-phone phoneCon"></i> +421 948 084 401</a><br></br>
                        <a href="mailto:ekonom@ekometal.sk" className="mailCon"><i className="fa fa-envelope enveCon"></i> ekonom@ekometal.sk</a>
                    </div>
                </div>
            </div>
        </ReactWOW>
            <div className="col-sm-4"></div>
        </div>
    </section>
    
        <div className="row container-fluid m-0">
        <ReactWOW animation='fadeIn'>
            <div className="col-sm-12 conBoxInfo">
                <h1 className="conSubTitle">KDE NÁS NÁJDETE ?</h1>
                <hr className="hrCustomContact"></hr>
            </div>
        </ReactWOW>
        </div>
    <section className="container-fluid">
        <ReactWOW animation='fadeIn'>
            <ul>
                <li className="conFluid">
                    <span className="conInfo"><i className="fa fa-building"></i> Ekometal spol. s r.o.</span>
                </li>
                <li className="conFluid">
                    <a href="https://www.google.com/maps/place/Ekometal+s.r.o./@48.6923112,19.9358472,14.27z/data=!4m5!3m4!1s0x0:0x303e62fdf39ac4eb!8m2!3d48.6956341!4d19.9281471" className="ekoMapAddress" target="_blank" rel="noopener noreferrer"><span className="conInfo"><i className="fa fa-map"></i> Hviezdoslavova 1140, 980 61 Tisovec, Slovensko</span></a>
                </li>
                <li className="conFluid">
                    <span className="conInfo"><i className="fa fa-info"></i> IČO: 36059820</span>
                </li>
                <li className="conFluid">
                    <span className="conInfoLast"><i className="fa fa-info"></i> IČ DPH: SK2021724001</span>
                </li>
            </ul>
        </ReactWOW>
    </section>
    <section className="container-fluid">
            <div className="row">
            <ReactWOW animation='fadeIn'>
                <div className="col-lg-12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13370.022249276708!2d19.928209334547454!3d48.68899283057682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473fdb9cc7d38f79%3A0x303e62fdf39ac4eb!2sEkometal%20s.r.o.!5e0!3m2!1ssk!2scz!4v1580808259561!5m2!1ssk!2scz" 
                    id="G_map" title="EkometalVideo" ></iframe>
                </div>
            </ReactWOW>
            </div>
        </section>
</div>
)
