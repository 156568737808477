import React from 'react';
import {Nav, Navbar, Container} from 'react-bootstrap';
import styled from 'styled-components';
import logo from '../multimedia/logo.png'
import '../App.css';

//Obrazky
import euVlajka from '../multimedia/vlajky/eu_projekt.png';

//Subory
import suborProjektEU from '../verejne_obstaravanie/projektEU2022/projektEU2022.pdf';

const Styles = styled.div`
  .navbar {
    background-color: white;
  }

  .navbar-brand {
    margin: 10px 10px 10px 10px
  }

  .navbar-brand, .navbar-nav .nav-link {
    color: white;

    &:hover {
      color: white;
    }
  }

  img.navLogo {
    width: 170px;
    height: 35px;
  }

  .navbar-nav {
    background-color: #00215e;
    line-height: 40px;

  }

  .navbar-nav .nav-link {
    text-transform: uppercase;
    font-size: 15px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
  }

  .nav-link:hover {
    background-color: #dc242a;
    border-radius: 30px 0 30px 0;
  }

  .navbar-collapse {
    text-align: center;
  }

  .navContainer {
    width: 100%;
    background-color: #00215e;
  }

  .nav-item.dropdown .dropdown-menu {
    text-align: center;
    padding: 0;
    border-right: 1px solid #dc242a;
    border-left: 1px solid #dc242a;
    border-bottom: 1px solid #dc242a;
    border-radius: 0;
    background-color: white;
  }

  .nav-item.dropdown .dropdown-item {
    color: #4c4c4c;
    text-transform: uppercase;
  }

  .nav-item.dropdown .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {
    border-radius: 30px 0 30px 0;
    background-color: #dc242a;
  }

  .navbar-light .navbar-nav .show > .nav-link {
    color: white;
  }

`;
export const NavigationBar = () => {
    return (
        <Styles>
            <Navbar expand="lg" className="fixed-top navbarChange">
                <Navbar.Brand href="/"><img src={logo} alt="Logo" className="navLogo navBrandOut"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Container className="navContainer">
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Item><Nav.Link href="/">Domov</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link href="/o-spolocnosti">O nás</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link href="/technologia">Technológia</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link href={"/projekty"}>Projekty</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link href="/galeria">Galéria</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link href="/kontakt">Kontakt</Nav.Link></Nav.Item>
                            <Nav.Item className={"projektEU"}><Nav.Link className={"imgProjekty"} href={suborProjektEU} target="_blank">
                                <img src={euVlajka} alt=""/>
                            </Nav.Link></Nav.Item>
                            {/*<Nav.Item><Nav.Link href="/#"><img src={svkVlajka} alt="SK" width="20px" height="13px"/></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/maintanancePage"><img src={engVlajka} alt="EN" width="20px" height="13px" className="vlajkaENG"/></Nav.Link></Nav.Item>*/}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Styles>
    )
}
