import React from 'react';
import { Link } from 'react-router-dom';

export const maintanancePage = () => (
    <div className="container">
        <div className="row">
                <div className="col-sm-12 py-5">
                    <div className="gearSpin">
                        <svg version="1.1" id="Vrstva_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 155.91 155.91" style={{enableBackground: "new 0 0 155.91 155.91"}}>
                        <path className="st0" d="M150.3,77.59L150.3,77.59c0.04,7.38-2.41,17.08-2.62,17.88l-1.71,0.81v-2.39l-2.19-2.3h-16.03
                            c4.52-12.73,1.16-24.75-0.03-28.27l16.05,0l2.19-2.3v-2.39l1.71,0.81c0.21,0.8,2.66,10.51,2.62,17.89h0c0,0.04,0,0.09,0,0.13
                            C150.3,77.5,150.3,77.54,150.3,77.59z"/>
                        <path className="st0" d="M97.52,8.35V9.7c-0.74,0.12-5.05,0.92-5.12,2.91v14.95c-13.1-4.78-25.53-1.14-28.78-0.01V12.61
                            c-0.07-1.98-4.38-2.78-5.12-2.91V8.35c9.65-2.74,19.41-2.55,19.51-2.54l0-0.23l0,0.23C78.12,5.81,87.87,5.61,97.52,8.35z"/>
                        <path className="st0" d="M97.52,145.21v1.35c-9.65,2.74-19.41,2.54-19.51,2.54l0,0.23l0-0.23c-0.1,0-9.85,0.2-19.51-2.54v-1.35
                            c0.74-0.12,5.05-0.92,5.12-2.91v-14.54c13.16,4.78,25.62,1.07,28.78-0.03v14.57C92.48,144.29,96.79,145.09,97.52,145.21z"/>
                        <path className="st0" d="M124.06,63.3L124.06,63.3c-3.25-0.9-2.74-4.25-2.64-4.76l-24.9-24.37c-1,0.2-1.85,0.06-2.54-0.42
                            c-0.99-0.69-1.38-1.88-1.5-2.43c-13.14-4.9-25.67-1.21-28.88-0.09c-0.11,0.51-0.48,1.8-1.52,2.52c-0.69,0.48-1.55,0.62-2.54,0.42
                            l-24.9,24.37c0.1,0.52,0.62,3.92-2.73,4.78l-0.03,0h-0.75C26.52,76,29.84,88.02,31.04,91.6h0.84c3.35,0.86,2.83,4.26,2.73,4.78
                            l24.9,24.37c1-0.2,1.85-0.06,2.54,0.42c1.29,0.9,1.55,2.65,1.56,2.73v0.13c13.12,4.84,25.6,1.16,28.78,0.05v-0.17
                            c0.01-0.09,0.27-1.85,1.56-2.75c0.69-0.48,1.55-0.62,2.54-0.42l24.9-24.37c-0.1-0.51-0.61-3.82,2.59-4.74
                            C128.62,78.9,125.24,66.8,124.06,63.3z M78.01,117.03c-21.75,0-39.44-17.69-39.44-39.44s17.69-39.44,39.44-39.44
                            s39.44,17.69,39.44,39.44S99.76,117.03,78.01,117.03z"/>
                        <path className="st0" d="M27.39,91.6l-15.13,0l-2.2,2.3v2.39l-1.71-0.81c-0.21-0.8-2.66-10.5-2.62-17.88h0c0-0.04,0-0.09,0-0.13
                            c0-0.04,0-0.09,0-0.13h0c-0.04-7.38,2.42-17.08,2.62-17.89l1.71-0.81v2.39l2.2,2.3h15.12C22.86,76.03,26.19,88.04,27.39,91.6z"/>
                        <path className="st0" d="M78.01,48.2c-16.21,0-29.39,13.19-29.39,29.39s13.19,29.39,29.39,29.39s29.39-13.18,29.39-29.39
                            S94.22,48.2,78.01,48.2z M77.97,99.18c-11.92,0-21.57-9.66-21.57-21.58s9.66-21.57,21.57-21.57s21.58,9.66,21.58,21.57
                            S89.89,99.18,77.97,99.18z"/>
                        </svg>
                    </div>
                </div>
        </div>
        <div className="row">
            <div className="col-sm-12 text-center">
                <h3 className="subTitle">Stránka je momentálne vo vývoji.</h3>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12 text-center py-5">
            <Link to="/" className="homeBtn404">Domov</Link>
            </div>
        </div>
    </div>
)
