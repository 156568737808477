import React from 'react';
import { Link } from 'react-router-dom';

export const technologia = () => (
    <div>
        <section className="container-fluid animated fadeIn">
            <div className="row">
                <div className="col-md-4 techBox">
                    <h2 className="oSpolTitle">Naša technológia</h2>
                    <ul className="sideBox">
                        <Link to="/delenie-materialu"><li className="sideNavbar">Delenie materiálu</li></Link>
                        <Link to="/sustruznicke-centra"><li className="sideNavbar">Sústružnické centrá</li></Link>
                        <Link to="/frezovacie-centra"><li className="sideNavbar">Frézovacie centrá</li></Link>
                        <Link to="/brusenie"><li className="sideNavbar">Brúsenie</li></Link>
                        <Link to="/omielacie-zariadenia"><li className="sideNavbar">Omieľacie zariadenia</li></Link>
                        <Link to="/vystupna-kontrola"><li className="sideNavbar">Výstupná kontrola</li></Link>
                    </ul>
                </div>
                <div className="col-md-8">
                    <h1 className="mainTitleTech">Sústružnícke centrá 3 – osé</h1>

                    <h5 className="titleMachine">TONGTAI TOPPER TC-2000 – 2 ks</h5>
                    <p className="machineNote">Max. sústružený priemer 320 mm</p>
                    <p className="machineNote">Max. točná dĺžka 600 mm</p>

                    <h5 className="titleMachine">LEADWELL 25 iL</h5>
                    <p className="machineNote">Max. sústružený priemer 450 mm</p>
                    <p className="machineNote">Max. točná dĺžka 1020 mm</p>

                    <h5 className="titleMachine">MAS S 50</h5>
                    <p className="machineNote">Max. sústružený priemer 430 mm</p>
                    <p className="machineNote">Max. točná dĺžka 520 mm</p>

                    <h5 className="titleMachine">LEADWELL T 7</h5>
                    <p className="machineNote">Max. sústružený priemer 350 mm</p>
                    <p className="machineNote">Max. točná dĺžka 500 mm</p>

                    <h5 className="titleMachine">LEADWELL T7A</h5>
                    <p className="machineNote">Max. sústružený priemer 350 mm</p>
                    <p className="machineNote">Max. točná dĺžka 500 mm</p>

                    <h5 className="titleMachine">LEADWELL TW 8 – 2 vretená, poháňané nástroje, manipulátor</h5>
                    <p className="machineNote">Max. sústružený priemer 290 mm</p>
                    <p className="machineNote">Max. točná dĺžka 160 mm</p>
                    <p className="machineNote">Max. priemer polotovaru do zásobníka na veľkosériovú výrobu 150 mm</p>

                    <h5 className="titleMachine">LEADWELL 25i</h5>
                    <p className="machineNote">Max. sústružený priemer 450 mm</p>
                    <p className="machineNote">Max. točná dĺžka 600 mm</p>

                    <h5 className="titleMachine">SBL 300 CNC – 2 vretená, poháňané nástroje</h5>
                    <p className="machineNote">Max. sústružený priemer 300 mm</p>
                    <p className="machineNote">Max. točná dĺžka 400 mm</p>

                    <h5 className="titleMachine">LEADWELL T 20 A</h5>
                    <p className="machineNote">Max. sústružený priemer 320 mm</p>
                    <p className="machineNote">Max. točná dĺžka 450 mm</p>

                    <h5 className="titleMachine">LEADWELL T 6</h5>
                    <p className="machineNote">Max. sústružený priemer 300 mm</p>
                    <p className="machineNote">Max. točná dĺžka 400 mm</p>
                </div>
            </div>
        </section>
    </div>
)
