import React from 'react'
import styled from 'styled-components';

//Subory
import suborSkladba_ceny from '../multimedia/projekty/info_tabula_eu.png';

const Styles = styled.div`
  h1.dotacieTitle {
    text-align: center;
    text-transform: uppercase;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  img {
    width: 100%;
  }
`;
export const dotacie = () => (
    <Styles>
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h1 className="dotacieTitle">Projekty</h1>
                    <hr className="hrCustomVO"/>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <img src={suborSkladba_ceny} alt=""/>
                </div>
            </div>
        </div>
    </Styles>
)
