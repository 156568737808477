import React from 'react'
import ReactWOW from 'react-wow';
import '../App.css';

import h1 from '../multimedia/historiaSpol/historia1.JPG';
import h2 from '../multimedia/historiaSpol/historia2.JPG';
import h3 from '../multimedia/historiaSpol/historia3.JPG';
import h4 from '../multimedia/historiaSpol/historia4.jpg';
import h5 from '../multimedia/historiaSpol/historia5.jpg';
import h6 from '../multimedia/historiaSpol/historia6.jpg';
import h7 from '../multimedia/historiaSpol/historia7.jpg';
import h8 from '../multimedia/historiaSpol/historia8.jpg';
import h9 from '../multimedia/historiaSpol/historia9.jpg';
import h10 from '../multimedia/historiaSpol/historia10.jpg';
import h11 from '../multimedia/historiaSpol/historia11.jpg';
import h12 from '../multimedia/historiaSpol/historia12.jpg';
import h13 from '../multimedia/historiaSpol/historia13.jpg';
import h14 from '../multimedia/historiaSpol/historia14.jpg';
import h15 from '../multimedia/historiaSpol/historia15.jpg';

export const oSpol = () => (
    <div className="container">
        <div className="row">
          <ReactWOW animation='fadeIn'>
              <div className="col-lg-12">
                  <h1 className="oTitle">EKOMETAL - HISTÓRIA SPOLOČNOSTI</h1>
              </div>
            </ReactWOW>
        </div>
        <div className="timeline_TM"> 
          <ReactWOW animation='fadeInLeft' delay="100ms">
            <div className="container_TM left_TM">
              <div className="content_TM text-center">
                <h2 className="text-center oSpolTitle_TM" style={{color: "white"}}>2003 - 2006</h2>
                <img src={h1} className="imgHis" alt="Historia"/>
                <img src={h2} className="imgHis" alt="Historia"/>
                <img src={h3} className="imgHis" alt="Historia"/>
              </div>
            </div>
          </ReactWOW>
            
          <ReactWOW animation='fadeInRight' delay="100ms">
            <div className="container_TM right_TM">
                <div className="content_TM text-center">
                  <h2 className="text-center oSpolTitle_TM" style={{color: "white"}}>2007 - 2009</h2>
                  <img src={h4} className="imgHis" alt="Historia"/>
                  <img src={h5} className="imgHis" alt="Historia"/>
                  <img src={h6} className="imgHis" alt="Historia"/>
                </div>
            </div>
          </ReactWOW>
          
          <ReactWOW animation='fadeInLeft' delay="100ms">
            <div className="container_TM left_TM">
              <div className="content_TM text-center">
                <h2 className="text-center oSpolTitle_TM" style={{color: "white"}}>2010 - 2012</h2>
                <img src={h7} className="imgHis" alt="Historia"/>
                <img src={h8} className="imgHis" alt="Historia"/>
                <img src={h9} className="imgHis" alt="Historia"/>
              </div>
            </div>
          </ReactWOW>
          
          <ReactWOW animation='fadeInRight' delay="100ms">
            <div className="container_TM right_TM">
              <div className="content_TM text-center">
                <h2 className="text-center oSpolTitle_TM" style={{color: "white"}}>2013 - 2016</h2>
                <img src={h10} className="imgHis" alt="Historia"/>
                <img src={h11} className="imgHis" alt="Historia"/>
                <img src={h12} className="imgHis" alt="Historia"/>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation='fadeInLeft' delay="100ms">
          <div id="oSpolFadeL" className="container_TM left_TM">
            <div className="content_TM text-center">
              <h2 className="text-center oSpolTitle_TM" style={{color: "white"}}>2017 - 2018</h2>
              <img src={h13} className="imgHis" alt="Historia"/>
              <img src={h14} className="imgHis" alt="Historia"/>
              <img src={h15} className="imgHis" alt="Historia"/>
            </div>
          </div>
          </ReactWOW>
          <ReactWOW animation='fadeInRight' delay="100ms">
          <div id="oSpolFadeVideo" className="container_TM right_TM">
            <div className="content_TM_video">
              <h2 className="text-center oSpolTitle_TM" style={{color: "white"}}>Súčasnosť</h2>
              <iframe id="oSpolVideo" className="text-center" title="oSpolVideo" width="462" height="255" src="https://www.youtube.com/embed/BKGfpsOaz44" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope;" allowFullScreen></iframe>
            </div>
          </div>
          </ReactWOW>
        </div>
    </div>
)